import React, { useState, useEffect } from "react";
import { Grid, TextField, Button, Typography } from "@material-ui/core";
import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import {
	addAlert,
	deleteAllAlerts,
	emptyImagesForAllPlaces,
} from "../../actions/admin.js";
import {
	acceptPlaceDescription,
	deletePlaceDescriptionById,
	getAllPlaceDescriptions,
} from "../../actions/placeDescription.js";
import LastPhotos from "./LastPhotos/LastPhotos.jsx";
import LastComments from "./LastComments/LastComments.jsx";
import LastPlaces from "./LastPlaces/LastPlaces.jsx";

const AdminPanel = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const user = JSON.parse(localStorage.getItem("profile"));
	const placeDescriptions = useSelector((state) => {
		return state.place.placeDescription;
	});

	useEffect(() => {
		dispatch(getAllPlaceDescriptions());
	}, []);

	const [notificationText, setNotificationText] = useState("");
	const [notificationType, setNotificationType] = useState("");
	const [expireAt, setExpireAt] = useState("");

	const handleNotificationTextChange = (event) => {
		setNotificationText(event.target.value);
	};

	const handleNotificationTypeChange = (event) => {
		setNotificationType(event.target.value);
	};

	const handleExpireAtChange = (event) => {
		setExpireAt(event.target.value);
	};

	const handleAcceptDescription = (descId) => {
		dispatch(acceptPlaceDescription(descId));
	};

	const handleDeclineDescription = (descId) => {
		dispatch(deletePlaceDescriptionById(descId));
	};

	const handleSendNotification = () => {
		dispatch(
			addAlert(notificationText, notificationType, expireAt, user?.result?._id)
		);
		setNotificationText("");
		setNotificationType("");
		setExpireAt("");
	};

	const handleEmptyImagesForAllPlaces = () => {
		dispatch(emptyImagesForAllPlaces());
	};

	const handleDeleteAllAlerts = () => {
		dispatch(deleteAllAlerts(user?.result?._id));
	};

	if (user?.result?.userType !== "admin") {
		return <Typography variant="h6">Вы не администратор</Typography>;
	}

	const hasNonEmptyHours = (workingHours) => {
		return Object.values(workingHours).some((hours) => hours.length > 0);
	};

	return (
		<div>
			<Grid
				container
				justifyContent="space-between"
				alignItems="stretch"
				spacing={3}
				className={classes.gridContainer}
			>
				<Grid item xs={12} sm={6} md={3}>
					<div>
						<Typography variant="h6">Удалить все уведомления</Typography>
						<Button
							variant="contained"
							color="secondary"
							onClick={handleDeleteAllAlerts}
						>
							Удалить
						</Button>
					</div>
				</Grid>
				<Grid item xs={12} sm={6} md={9}>
					<div>
						<Typography variant="h5">Добавить уведомление</Typography>
						<TextField
							label="Текст уведомления"
							variant="outlined"
							value={notificationText}
							onChange={handleNotificationTextChange}
							fullWidth
							margin="normal"
						/>
						<TextField
							label="Тип уведомления"
							variant="outlined"
							value={notificationType}
							onChange={handleNotificationTypeChange}
							fullWidth
							margin="normal"
						/>
						<TextField
							label="Срок действия"
							variant="outlined"
							type="date"
							value={expireAt}
							onChange={handleExpireAtChange}
							fullWidth
							margin="normal"
							InputLabelProps={{
								shrink: true,
							}}
						/>
						<Button
							variant="contained"
							color="primary"
							onClick={handleSendNotification}
						>
							Отправить уведомление
						</Button>
					</div>
				</Grid>
			</Grid>
			<div className={classes.outerContainerDesc}>
				{placeDescriptions ? (
					placeDescriptions.map((c) => (
						<div key={c?._id} className={classes.containerDesc}>
							<Typography variant="h6">{c?.place}</Typography>
							<Typography>{c?.description}</Typography>
							{c?.phone && <Typography>Phone: {c?.phone}</Typography>}
							{c?.website && <Typography>Website: {c?.website}</Typography>}
							{c?.workingHours && hasNonEmptyHours(c.workingHours) && (
								<div>
									<Typography variant="subtitle1">Working Hours:</Typography>
									{Object.entries(c.workingHours).map(([day, hours]) => (
										<Typography key={day}>
											{day.charAt(0).toUpperCase() + day.slice(1)}:{" "}
											{hours.join(" - ")}
										</Typography>
									))}
								</div>
							)}

							{c?.paymentMethod && (
								<Typography>Payment Method: {c?.paymentMethod}</Typography>
							)}
							{c?.cuisine && (
								<Typography>Cuisine: {c?.cuisine.join(", ")}</Typography>
							)}
							<Button
								className={classes.buttonDesc}
								onClick={() => handleAcceptDescription(c?._id)}
								variant="contained"
								color="primary"
							>
								Принять
							</Button>
							<Button
								className={classes.buttonDesc}
								onClick={() => handleDeclineDescription(c?._id)}
								variant="contained"
								color="secondary"
							>
								Отклонить
							</Button>
						</div>
					))
				) : (
					<Typography>No place descriptions available.</Typography>
				)}
			</div>
			<LastPhotos />
			<LastComments />
			<LastPlaces />
			<Button
				variant="contained"
				color="secondary"
				onClick={handleEmptyImagesForAllPlaces}
			>
				Удалить фото из мест
			</Button>
		</div>
	);
};

export default AdminPanel;
