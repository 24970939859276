import React, { useState, useEffect } from "react";
import {
	Typography,
	Grid,
	Box,
	IconButton,
	Link as MuiLink,
	Menu,
	MenuItem,
	TextField,
	Button,
} from "@material-ui/core";
import { ArrowBack, ArrowForward, MoreVert } from "@material-ui/icons"; // Added MoreVert for the menu button
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "./styles.js";
import { getPhotoByUrl } from "../../../../actions/photo.js";
import AddClaim from "../../../AddClaim/AddClaim.jsx";
import AuthorInfo from "../../../AuthorInfo/AuthorInfo.jsx";

const ImageSection = React.memo(
	({ title, icon, images, sectionId, place, user }) => {
		const classes = useStyles();
		const history = useHistory();
		const dispatch = useDispatch();
		const [currentIndex, setCurrentIndex] = useState(0);
		const [anchorEl, setAnchorEl] = useState(null); // State for menu
		const [reportOpen, setReportOpen] = useState(false);
		const photoObject = useSelector(
			(state) => state?.photo?.selectedPhotos[sectionId]
		);

		// Function to handle image click and navigate to the photo page
		const handleImageClick = (imageUrl) => {
			const urlParts = imageUrl.split("/");
			const folder = urlParts[urlParts.length - 2];
			const name = urlParts[urlParts.length - 1];
			history.push(`/photo/${folder}/${name}`);
		};

		// Function to navigate to the next image
		const handleNextImage = () => {
			setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
		};

		// Function to navigate to the previous image
		const handlePrevImage = () => {
			setCurrentIndex(
				(prevIndex) => (prevIndex - 1 + images.length) % images.length
			);
		};

		// Function to copy image URL to clipboard
		const handleCopyUrl = () => {
			const currentImageUrl = images[currentIndex].image;
			navigator.clipboard.writeText(currentImageUrl).then(() => {
				alert("URL скопирован");
			});
			setAnchorEl(null); // Close the menu
		};

		// Function to open the report item
		const handleReport = () => {
			setReportOpen(true);
			setAnchorEl(null); // Close the menu
		};

		// Open menu
		const handleMenuClick = (event) => {
			setAnchorEl(event.currentTarget);
		};

		// Close menu
		const handleMenuClose = () => {
			setAnchorEl(null);
		};

		useEffect(() => {
			const currentImageUrl =
				images && images.length > 0 ? images[currentIndex].image : null;
			if (currentImageUrl) {
				dispatch(getPhotoByUrl(currentImageUrl, sectionId));
			}
		}, [dispatch, currentIndex, images, sectionId]);

		if (!images || images.length === 0) return null;

		return (
			<>
				<Box className={classes.boxType}>
					<img src={icon} className={classes.boxTypeImg} alt={title} />
					<Typography variant="subtitle1" className={classes.boxTypeText}>
						{title}
					</Typography>
				</Box>

				<Box className={classes.sliderContainer}>
					{/* Left Arrow Button */}
					<IconButton
						className={classes.arrowButtonLeft}
						onClick={handlePrevImage}
						disabled={images.length <= 1}
					>
						<ArrowBack />
					</IconButton>

					{/* Display the current image */}
					<Box className={classes.imageWrapper}>
						<img
							src={images[currentIndex].image}
							alt={`Photo ${currentIndex + 1}`}
							className={classes.image}
							onClick={() => handleImageClick(images[currentIndex].image)}
						/>
						{/* Menu Button */}
						<IconButton
							className={classes.menuButton}
							onClick={handleMenuClick}
						>
							<MoreVert />
						</IconButton>
						<Menu
							anchorEl={anchorEl}
							open={Boolean(anchorEl)}
							onClose={handleMenuClose}
						>
							<MenuItem onClick={handleCopyUrl}>Скопировать URL фото</MenuItem>
							<MenuItem onClick={handleReport}>Пожаловаться</MenuItem>
						</Menu>

						{/* Display author information */}
						<AuthorInfo photoObject={photoObject} type="large" />
					</Box>

					{/* Right Arrow Button */}
					<IconButton
						className={classes.arrowButtonRight}
						onClick={handleNextImage}
						disabled={images.length <= 1}
					>
						<ArrowForward />
					</IconButton>
				</Box>

				{/* AddClaim Component */}
				{reportOpen && (
					<AddClaim
						setReportOpen={setReportOpen}
						photoUrl={images[currentIndex].image}
						placeId={place?._id}
						userId={user?._id}
					/>
				)}
			</>
		);
	}
);

export default ImageSection;
