import {
	CREATE_PLACE_SELECTION,
	FETCH_PLACE_SELECTIONS,
	GET_PLACE_SELECTION_BY_ID,
} from "../constants/actionTypes";

const placeSelectionsReducer = (
	state = {
		placeSelections: [],
		placeSelection: null,
	},
	action
) => {
	switch (action.type) {
		case CREATE_PLACE_SELECTION:
			return {
				...state,
				placeSelections: [...state.placeSelections, action.payload],
			};
		case FETCH_PLACE_SELECTIONS:
			return {
				...state,
				placeSelections: action.payload.data,
			};
		case GET_PLACE_SELECTION_BY_ID:
			return {
				...state,
				placeSelection: action.payload,
			};
		default:
			return state;
	}
};

export default placeSelectionsReducer;
