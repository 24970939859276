import axios from "axios";

const API = axios.create({ baseURL: "https://totravelserver.ru:5001" });

API.interceptors.request.use((req) => {
	const token = document.cookie
		.split("; ")
		.find((row) => row.startsWith("token="))
		?.split("=")[1];

	if (token) {
		req.headers.Authorization = `Bearer ${token}`;
	}

	return req;
});

export const fetchPosts = (page) => API.get(`/posts?page=${page}`);
export const fetchPostsByUser = (login, page) =>
	API.get(`/user/${login}/getPosts?page=${page}`);
export const fetchRandomPost = () => API.get("/main");
export const fetchReportPosts = (posts) => API.get(`/posts/${posts}`);
export const fetchPostContent = (post) => API.get(`/post/${post}`);
export const fetchPost = (id) => API.get(`/posts/${id}`);
export const fetchPostsBySearch = (searchQuery) =>
	API.get(
		`/posts/search?searchQuery=${searchQuery.search || "none"}&tags=${
			searchQuery.tags
		}`
	);
export const fetchNextPost = (id) => API.get(`/post/${id}/nextPost`);
export const fetchPreviousPost = (id) => API.get(`/post/${id}/prevPost`);
export const createPost = (newPost) => API.post("/posts", newPost);
export const updatePost = (id, updatedPost) =>
	API.patch(`/posts/${id}`, updatedPost);
export const deletePost = (id) => API.delete(`/posts/${id}`);
export const deletePostReport = (id) => API.delete(`/post/${id}`);
export const likePost = (id) => API.patch(`/posts/${id}/likePost`);
export const comment = (value, id) =>
	API.post(`/posts/${id}/commentPost`, { value });
export const sendResponseCommentReport = (value, id) =>
	API.post(`/posts/${id}/sendResponseCommentReport`, { value });
export const addNewPost = (value, id) =>
	API.post(`/posts/${id}/addPost`, { value });
export const addYandexImage = (image) =>
	API.post(`/posts/addYandexImage`, { image });
export const addYandexImageReport = (image) =>
	API.post(`/posts/addYandexImageReport`, { image });
export const deleteYandexImage = (path) =>
	API.post(`/posts/deleteYandexImage`, { path });
export const commentPost = (value, id) =>
	API.post(`/post/${id}/commentPost`, { value });
export const sendResponseCommentPost = (value, id) =>
	API.post(`/post/${id}/sendResponseCommentPost`, { value });
export const likePostReport = (id) => API.patch(`/post/${id}/likePost`);
export const likeCommentPost = (value, postId) =>
	API.patch(`/post/${postId}/likeComment`, value);
export const likePostInReport = (value, reportId) =>
	API.patch(`/posts/${reportId}/likePostInReport`, value);
export const likeCommentReport = (value, reportId) =>
	API.patch(`/posts/${reportId}/likeComment`, value);
export const updatePostReport = (id, updatedPostReport) =>
	API.patch(`/post/${id}/updatePost`, updatedPostReport);
export const getPostSmall = (post) => API.get(`/post/${post}/getPostSmall`);

export const signIn = async (formData) => {
	try {
		const response = await API.post("/user/signin", formData);
		// Set the token as an HTTP-only cookie
		document.cookie = `token=${response.data.token}; path=/;`;
		return response.data;
	} catch (error) {
		throw error.response.data.message;
	}
};
export const signUp = (formData) => API.post("/user/signup", formData);
export const updateProfile = (updateProfile, login) =>
	API.post(`/user/${login}`, updateProfile);
export const updateCountries = (updateCountries, login) =>
	API.post(`/user/${login}/updateCountries`, updateCountries);
export const getUserInfo = (id) => API.get(`/user/${id}`);
export const getUserInfoByLogin = (login) => API.get(`/user/${login}`);
export const getPostUserInfo = (id) => API.get(`/post/${id}/user`);
export const getPostUserImg = (id) => API.get(`/post/${id}/userImg`);
export const getReportUserInfo = (id) => API.get(`/posts/${id}/user`);
export const addYandexAvatar = (image) =>
	API.post(`/user/addYandexAvatar`, { image });
export const getNumberUsers = () => API.get("/user/getNumberUsers");
export const getNumberFriends = (login) =>
	API.get(`/user/getNumberFriends?login=${login}`);
export const getNumberSubscribers = (login) =>
	API.get(`/user/getNumberSubscribers?login=${login}`);
export const getUsers = (page, searchText) =>
	API.get(`/user/getUsers?page=${page}&searchText=${searchText}`);
export const addFriend = (senderId, receiverId) =>
	API.patch(`/user/addFriend`, { senderId, receiverId });
export const unsubscribe = (senderId, receiverId) =>
	API.patch(`/user/unsubscribe`, { senderId, receiverId });
export const getSubscribersByLogin = (login) =>
	API.get(`/user/getSubscribersByLogin/${login}`);
export const getFriendsByLogin = (login) =>
	API.get(`/user/getFriendsByLogin/${login}`);
export const removeFriend = (senderId, receiverId) =>
	API.patch(`/user/removeFriend`, { senderId, receiverId });
export const getFriends = (page, searchText, login) =>
	API.get(
		`/user/getFriends?page=${page}&searchText=${searchText}&login=${login}`
	);
export const getSubscribers = (page, searchText, login) =>
	API.get(
		`/user/getSubscribers?page=${page}&searchText=${searchText}&login=${login}`
	);

export const confirmEmail = (token) => API.get(`/tokenVerify/${token}`);
export const resendConfirmEmail = (email) => API.post(`/verify`, email);
export const sendChangePassword = (email) =>
	API.post(`/changePasswordAsk`, email);
export const changePassword = (token, formData) =>
	API.post(`/changePassword/${token}`, formData);

export const getNotifications = (id, page, type) =>
	API.get(`/notifications/${id}/getNotifications?page=${page}&type=${type}`);
export const getUnviewed = (id, type) =>
	API.get(`/notifications/${id}/getUnviewed?type=${type}`);
export const viewAllNotifications = (id) =>
	API.patch(`/notifications/${id}/viewAllNotifications`);
export const deleteAllNotifications = (id) =>
	API.patch(`/notifications/${id}/deleteAllNotifications`);
export const deleteNotification = (id) =>
	API.patch(`/notifications/${id}/deleteNotification`);
export const acceptFriendFromNotification = (id) =>
	API.patch(`/notifications/${id}/acceptFriendFromNotification`);
export const leaveAsSubscriber = (id) =>
	API.patch(`/notifications/${id}/leaveAsSubscriber`);

export const addAlert = (alertText, type, expireAt, id) =>
	API.post(`/admin/${id}/addAlert`, { alertText, type, expireAt });
export const getLatestAlert = () => API.get("/admin/getLatestAlert");
export const getLastPhotos = () => API.get("/admin/getLastPhotos");
export const getLastComments = () => API.get("/admin/getLastComments");
export const getLastPlaces = () => API.get("/admin/getLastPlaces");
export const deleteAllAlerts = (id) =>
	API.patch(`/admin/${id}/deleteAllAlerts`);

export const addPlace = (place) => API.post(`/place/addPlace`, { place });
export const getPlace = (id) => API.get(`/place/${id}/getPlace`);
export const addPostToPlace = (placeId, rate, postId) =>
	API.post(`/place/addPostToPlace`, { placeId, rate, postId });
export const addCityToPlace = (placeId, cityId) =>
	API.post(`/place/addCityToPlace`, { placeId, cityId });
export const addYandexPlace = (image) =>
	API.post(`/place/addYandexPlace`, { image });
export const addPhotosToPlace = (newImages) =>
	API.post(`/place/addPhotosToPlace`, newImages);
export const emptyImagesForAllPlaces = () =>
	API.patch(`/place/emptyImagesForAllPlaces`);

export const getPlaces = (limit) => API.get(`/places/getPlaces?limit=${limit}`);
export const getPlacesByPostsId = (postsId) =>
	API.get(`/places/getPlacesByPostsId?postsId=${postsId}`);
export const checkPlacesByPostsId = (postsId) =>
	API.get(`/places/checkPlacesByPostsId?postsId=${postsId}`);
export const searchPosts = (searchString, filter) =>
	API.get(`/places/searchPosts?searchString=${searchString}&filter=${filter}`);
export const getPlacesByBorders = (limit, firstBorder, secondBorder) =>
	API.get(
		`/places/getPlacesByBorders?limit=${limit}&firstBorder=${firstBorder}&secondBorder=${secondBorder}`
	);
export const findPlacesByCity = (city, page, filter) =>
	API.get(
		`/places/findPlacesByCity?city=${city}&page=${page}&filter=${filter}`
	);
export const findPlaces = (page, filter, sortType, longitude, latitude) =>
	API.get(
		`/places/findPlaces?page=${page}&filter=${filter}&sortType=${sortType}&longitude=${longitude}&latitude=${latitude}`
	);
export const getPlacesByIds = (ids) =>
	API.get(`/places/getPlacesByIds?ids=${ids}`);

export const addPlaceDescription = (description) =>
	API.post(`/placeDescription/addPlaceDescription`, { description });
export const addHowToGet = (description) =>
	API.post(`/placeDescription/addHowToGet`, { description });
export const getAllPlaceDescriptions = () =>
	API.get(`/placeDescription/getAllPlaceDescriptions`);
export const deletePlaceDescriptionById = (id) =>
	API.delete(`/placeDescription/${id}/deletePlaceDescriptionById`);
export const acceptPlaceDescription = (id) =>
	API.patch(`/placeDescription/${id}/acceptPlaceDescription`);

export const fetchPostsFeed = (sort) =>
	API.get(`/feed/fetchPostsFeed?sort=${sort}`);
export const fetchMorePostsFeed = (lastPostIndex, sort) =>
	API.get(
		`/feed/fetchMorePostsFeed?lastPostIndex=${lastPostIndex}&sort=${sort}`
	);
export const fetchPostsFeedFriend = (userId) =>
	API.get(`/feed/fetchPostsFeedFriend?userId=${userId}`);
export const fetchMorePostsFeedFriend = (lastPostIndex, userId) =>
	API.get(
		`/feed/fetchMorePostsFeedFriend?lastPostIndex=${lastPostIndex}&userId=${userId}`
	);

export const addYandexPlaceReview = (image) =>
	API.post(`/placeReview/addYandexPlaceReview`, { image });
export const createPlaceReview = (newReview) =>
	API.post("/placeReview/createPlaceReview", newReview);
export const getPlaceReviews = (page, placeId) =>
	API.get(`/placeReview/getPlaceReviews?page=${page}&placeId=${placeId}`);

export const createPlaceSelection = (newSelection) =>
	API.post(`/placeSelections/createPlaceSelection`, { newSelection });
export const fetchPlaceSelections = (page, limit) =>
	API.get(`/placeSelections/fetchPlaceSelections?page=${page}&limit=${limit}`);
export const getPlaceSelectionById = (id) =>
	API.get(`/placeSelections/getPlaceSelectionById?id=${id}`);
export const updatePlaceSelection = (id, selection) =>
	API.patch(`/placeSelections/${id}/updatePlaceSelection`, { selection });

export const getPhotoByUrl = (url) =>
	API.get(`/lastPhotos/getPhotoByUrl?url=${url}`);

export const addClaim = (claim) => API.post(`/claim/addClaim`, { claim });
