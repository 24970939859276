import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography, Box, Button } from "@material-ui/core";
import { getPlacesByIds } from "../../../../actions/places";
import { useParams } from "react-router-dom"; // Hook to get the ID from URL
import { getPlaceSelectionById } from "../../../../actions/placeSelections";
import PlaceCard from "../../Place/PlaceCityToDo/PlacesInCityList/PlaceCard/PlaceCard";
import PlaceSelectionChange from "../PlaceSelectionChange/PlaceSelectionChange";
import { getPhotoByUrl } from "../../../../actions/photo";
import AuthorInfo from "../../../AuthorInfo/AuthorInfo";
//import PlaceSelectionChange from "./PlaceSelectionChange"; // Import the change component

const PlaceSelection = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { id } = useParams(); // Get place selection ID from the URL
	const [isEditing, setIsEditing] = useState(false); // Toggle edit mode
	const [isLoading, setIsLoading] = useState(true); // Toggle edit mode
	let user = JSON.parse(localStorage.getItem("profile"));
	user = user?.result;

	const placeSelection = useSelector(
		(state) => state?.placeSelections?.placeSelection
	);
	const places = useSelector((state) => state?.place?.placesByIds); // Assuming loadedPlaces is the array of places
	const selectionPhotoObject = useSelector(
		(state) => state?.photo?.selectedPhotos["placeSelectionPhoto"]
	);

	const mainPhoto =
		placeSelection?.images?.[0]?.image ||
		placeSelection?.placeImageExample?.image;

	useEffect(() => {
		if (id) {
			setIsLoading(true);
			dispatch(getPlaceSelectionById(id));
			setIsLoading(false);
		}
	}, [dispatch, id]);

	useEffect(() => {
		if (placeSelection && placeSelection.places) {
			dispatch(getPlacesByIds(placeSelection.places));
		}
	}, [dispatch, placeSelection]);

	useEffect(() => {
		if (mainPhoto) {
			dispatch(getPhotoByUrl(mainPhoto, "placeSelectionPhoto"));
		}
	}, [dispatch, mainPhoto]);

	if (isLoading && !placeSelection) {
		return <Typography variant="h6">Загрузка...</Typography>;
	}

	if (!isLoading && !placeSelection) {
		return <Typography variant="h6">Подборка не найдена</Typography>;
	}

	const handleEditClick = () => {
		setIsEditing((prev) => !prev);
	};

	return (
		<>
			<Helmet>
				<title>{placeSelection?.title} - список мест - ToTravelRu</title>
				<meta
					name="description"
					content={`${placeSelection?.title} - подборка мест, список мест, какие места посетить - ToTravelRu`}
				/>
				<meta
					name="keywords"
					content={`${placeSelection?.title}, подборки, места, путешествия, ToTravelRu`}
				/>
			</Helmet>

			{isEditing ? (
				<PlaceSelectionChange
					placeSelection={placeSelection}
					onClose={handleEditClick}
					places={places}
				/>
			) : (
				<Box>
					{/* Display the main photo */}
					{mainPhoto && (
						<Box
							mb={2}
							style={{
								height: "300px",
								overflow: "hidden",
								position: "relative",
							}}
						>
							<img
								src={mainPhoto}
								alt={placeSelection.title}
								style={{
									width: "100%",
									height: "100%",
									maxHeight: "300px",
									objectFit: "cover",
									objectPosition: "center",
									borderRadius: "8px",
								}}
							/>
							<div className={classes.authorBox}>
								<AuthorInfo photoObject={selectionPhotoObject} />
							</div>
						</Box>
					)}
					<Typography variant="h4" gutterBottom align="center">
						{placeSelection.title}
					</Typography>

					<Typography variant="body1" paragraph align="center">
						{placeSelection.description}
					</Typography>

					{user?._id === placeSelection?.creator ? (
						<Box display="flex" justifyContent="center" mb={2}>
							<Button
								variant="contained"
								color="secondary"
								onClick={handleEditClick}
								className={classes.buttonPrimary}
							>
								Изменить подборку
							</Button>
						</Box>
					) : null}

					<Grid container spacing={3}>
						{places?.map((place, i) => (
							<Grid item xs={12} sm={12} md={12} key={place._id}>
								<PlaceCard i={i + 1} p={place} cardType="placeInSelection" />
							</Grid>
						))}
					</Grid>
				</Box>
			)}
		</>
	);
};

export default PlaceSelection;
