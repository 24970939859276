import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getPostSmall } from "../../../../actions/posts";
import {
	Typography,
	Paper,
	Button,
	CardMedia,
	Box,
	CardContent,
	Card,
	ButtonBase,
	CardActions,
} from "@material-ui/core";
import moment from "moment";
import { Link } from "react-router-dom";
import useStyles from "./styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import ThumbUpAltOutlined from "@material-ui/icons/ThumbUpAltOutlined";
import { likePostReport } from "../../../../actions/posts.js";

const PostInPlace = ({ postId }) => {
	const classes = useStyles();
	const post = useSelector((state) => state.posts?.post);
	const dispatch = useDispatch();
	const user = JSON.parse(localStorage.getItem("profile"));
	const [likes, setLikes] = useState();
	useEffect(() => {
		setLikes(post?.likes);
	}, [post]);
	const handleLike = async () => {
		dispatch(likePostReport(post.reportPostId));

		if (post.likes.find((like) => like === user?.result?._id)) {
			setLikes(
				post.likes.filter(
					(id) => id !== (user?.result?.googleId || user?.result?._id)
				)
			);
		} else {
			setLikes([...post.likes, user?.result?._id]);
		}
	};
	const Likes = () => {
		if (likes?.length > 0) {
			return likes.find((like) => like === user?.result?._id) ? (
				<>
					<ThumbUpAltIcon fontSize="small" sx={{ mr: "4px" }} />
					&nbsp;
					{likes.length > 2
						? `Вы и ${likes.length - 1} других`
						: `${likes.length} лайк${likes.length > 1 ? "а" : ""}`}
				</>
			) : (
				<>
					<ThumbUpAltOutlined fontSize="small" />
					&nbsp;{likes.length} {likes.length === 1 ? "Лайк" : "Лайка"}
				</>
			);
		}
		return (
			<>
				<ThumbUpAltOutlined fontSize="small" />
				&nbsp;Лайк
			</>
		);
	};

	useEffect(() => {
		dispatch(getPostSmall(postId));
	}, []);

	return (
		<>
			{post ? (
				<Card className={classes.card} raised elevation={6}>
					<ButtonBase
						component={Link}
						to={`/post/${postId}`}
						variant="contained"
						className={classes.cardAction}
					>
						{post?.previewImage?.image ? (
							<CardMedia
								className={classes.media}
								image={post.previewImage.image}
								title={post?.title}
							/>
						) : (
							<div className={classes.media}></div>
						)}
						<div className={classes.overlay}>
							<Typography variant="h6">{post.creatorName}</Typography>
							<Typography variant="body2">
								{moment(post.createdAt).fromNow()}
							</Typography>
						</div>
						<Typography className={classes.title} variant="h5" gutterBottom>
							{post.title}
						</Typography>
						<CardContent>
							<Typography variant="body2" color="textSecondary" component="p">
								{post.message}
							</Typography>
						</CardContent>
					</ButtonBase>
					<CardActions className={classes.cardActions}>
						<Button
							size="small"
							className={classes.buttonLike}
							disabled={!user?.result}
							onClick={handleLike}
						>
							<Likes />
						</Button>
						<Box className={classes.visitedBox}>
							<VisibilityIcon
								fontSize="small"
								className={classes.visitedIcon}
							/>
							<Typography>{post?.visited}</Typography>
						</Box>
					</CardActions>
				</Card>
			) : null}
		</>
	);
};

export default PostInPlace;
