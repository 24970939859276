import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
	Grid,
	Grow,
	Typography,
	Paper,
	Button,
	List,
	ListItem,
} from "@material-ui/core";
import useStyles from "./styles.js";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import PlaceSelectionCreate from "../PlaceSelectionCreate/PlaceSelectionCreate.jsx";
import { fetchPlaceSelections } from "../../../../actions/placeSelections.js";
import PlaceCard from "../../Place/PlaceCityToDo/PlacesInCityList/PlaceCard/PlaceCard.jsx";
import PaginationPlaceSelection from "./PaginationPlaceSelection/PaginationPlaceSelection.jsx";

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const PlaceSelection = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const query = useQuery();

	const [isCreating, setIsCreating] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const selections = useSelector(
		(state) => state?.placeSelections?.placeSelections
	);
	const page = query.get("page") || 1;
	const limit = 10;

	const handleCreateSelection = () => {
		setIsCreating(true);
	};

	useEffect(() => {
		const fetchSelections = async () => {
			setIsLoading(true);
			await dispatch(fetchPlaceSelections(page, limit));
			setIsLoading(false);
		};
		fetchSelections();
	}, []);

	return (
		<div className={classes.selectionContainer}>
			<Helmet>
				<title>Подборки мест - ToTravelRu</title>
				<meta
					name="description"
					content="Список подборок мест для путешествий."
				/>
				<meta
					name="keywords"
					content="подборки, места, путешествия, ToTravelRu"
				/>
			</Helmet>
			<div className={classes.titleButton}>
				<Typography variant="h5">Подборки мест</Typography>
				<Button
					variant="contained"
					color="primary"
					onClick={handleCreateSelection}
					className={classes.createButton}
				>
					Создать свою подборку
				</Button>
			</div>
			{isCreating && (
				<PlaceSelectionCreate onClose={() => setIsCreating(false)} />
			)}
			{isLoading ? (
				<Typography variant="body1">Загрузка...</Typography>
			) : (
				<Grid container>
					{selections?.length > 0 ? (
						selections.map((selection, index) => (
							<Grid item xs={12} sm={6} md={6} key={index}>
								<PlaceCard p={selection} cardType={"selection"} />
							</Grid>
						))
					) : (
						<Typography variant="body1">Нет доступных подборок.</Typography>
					)}
				</Grid>
			)}
			{selections?.length ? (
				<Paper className={classes.pagination}>
					<PaginationPlaceSelection page={page} />
				</Paper>
			) : null}
		</div>
	);
};

export default PlaceSelection;
