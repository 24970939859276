import React, { useState, useEffect } from "react";
import {
	Card,
	CardContent,
	Typography,
	Avatar,
	Grid,
	Link,
	Dialog,
	DialogContent,
	IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import StarRating from "../../../../Maps/ShowGeo/PlaceBox/StarRating";
import {
	currencyChange,
	translateRoomType,
} from "../../../../../utils/placeUtils";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
	root: {
		marginLeft: "auto",
		marginRight: "auto",
		maxWidth: 600,
		border: "1px solid",
		borderColor: "rgba(236,98,98,1)",
		borderRadius: "15px",
		position: "relative",
		"@media (max-width: 650px)": {
			width: "100%",
		},
	},
	ratingBox: {
		position: "absolute",
		top: theme.spacing(1),
		right: theme.spacing(1),
		backgroundColor: "rgba(236,98,98,0.9)",
		color: "black",
		padding: theme.spacing(1),
		borderRadius: "10px",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		zIndex: 1,
	},
	cardContent: {
		padding: "12px",
		"@media (max-width: 600px)": {
			padding: "6px",
		},
		"&:last-child": {
			paddingBottom: "0",
		},
	},
	image: {
		height: "70px",
		cursor: "pointer",
		borderRadius: "5px",
		marginRight: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	avatar: {
		border: "1px solid rgba(236,98,98,1)",
	},
	userReviewContainer: {
		alignItems: "center",
	},
	reviewText: {
		marginBottom: "1rem",
	},
	reviewUserName: {
		lineHeight: "1",
	},
	reviewUserLink: {
		color: "rgb(0,0,0)",
	},
	dialogNavButtons: {
		position: "absolute",
		top: "50%",
		transform: "translateY(-50%)",
		color: "#fff",
	},
	dialogImage: {
		width: "100%",
		maxHeight: "90vh", // Limit height to 80% of the viewport height
		objectFit: "contain", // Maintain aspect ratio
		borderRadius: "8px",
	},
	positionIndicator: {
		display: "flex",
		position: "absolute",
		bottom: "10px",
		left: "50%",
		transform: "translateX(-50%)",
		color: "#fff",
		backgroundColor: "rgba(0,0,0,0.5)",
		padding: "5px",
		borderRadius: "5px",
	},
	reviewPlaceRateText: {
		color: "rgb(256,256,256)",
	},
	statistics: {
		display: "flex",
		flexDirection: "row",
		backgroundColor: "rgba(236,98,98,1)",
		borderRadius: "10px",
		width: "fit-content",
		marginBottom: "8px",
		color: "rgb(256,256,256)",
		marginRight: "10px",
		alignItems: "center",
	},
	boxTypeImg: {
		width: "20px",
		margin: "0 5px",
		color: "rgb(255,255,255)",
	},
	currencyText: {
		fontSize: "0.9rem",
		marginRight: "5px",
	},
	spentText: {
		fontSize: "0.9rem",
	},
	flex: {
		display: "flex",
	},
	flexWrap: {
		display: "flex",
		flexWrap: "wrap",
	},
}));

const PlaceReviewCard = ({ place, review }) => {
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const [selectedImageIndex, setSelectedImageIndex] = useState(0);

	const handleClickOpen = (index) => {
		setSelectedImageIndex(index);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleNext = () => {
		setSelectedImageIndex(
			(prevIndex) => (prevIndex + 1) % review?.images.length
		);
	};

	const handlePrevious = () => {
		setSelectedImageIndex(
			(prevIndex) =>
				(prevIndex - 1 + review?.images.length) % review?.images.length
		);
	};

	const handleKeyDown = (event) => {
		if (event.key === "ArrowRight") {
			handleNext();
		} else if (event.key === "ArrowLeft") {
			handlePrevious();
		}
	};

	useEffect(() => {
		if (open) {
			window.addEventListener("keydown", handleKeyDown);
		}
		return () => {
			window.removeEventListener("keydown", handleKeyDown);
		};
	}, [open]);

	// Function to format the review date using native JavaScript
	const formattedDate = review?.createdAt
		? moment(review?.createdAt).locale("ru").fromNow()
		: "";

	return (
		<>
			<Card className={classes.root}>
				<div className={classes.ratingBox}>
					<StarRating rating={review.placeRate} />
				</div>
				<CardContent className={classes.cardContent}>
					<Grid container spacing={2} className={classes.userReviewContainer}>
						<Grid item>
							<Avatar
								src={review?.user?.picture}
								alt={review?.user?.name}
								className={classes.avatar}
							/>
						</Grid>
						<Grid item>
							<Typography variant="h6" className={classes.reviewUserName}>
								{review?.user?.name}
							</Typography>
							<Link
								href={`/user/${review?.user?.login}`}
								variant="body2"
								className={classes.reviewUserLink}
							>
								@{review?.user?.login}
							</Link>
						</Grid>
					</Grid>
					<Typography variant="body2" color="textSecondary">
						{formattedDate}
					</Typography>
					<Typography variant="body1" className={classes.reviewText}>
						{review.text}
					</Typography>
					<Grid container spacing={1}>
						{review?.images.map((image, index) => (
							<Grid item key={index}>
								<img
									src={image}
									alt={`Review image ${index}`}
									className={classes.image}
									onClick={() => handleClickOpen(index)}
								/>
							</Grid>
						))}
					</Grid>
					{/* Rest of the content */}
					<div className={classes.flexWrap}>
						{review?.roomType && review?.roomType !== "" ? (
							<div className={classes.statistics}>
								<img
									src="https://img.icons8.com/sf-regular/48/FFFFFF/bed.png"
									className={classes.boxTypeImg}
								/>
								<Typography className={classes.currencyText}>
									{translateRoomType(review?.roomType)}
								</Typography>
							</div>
						) : null}
						{review?.spentAmount > 0 ? (
							<div className={classes.statistics}>
								<img
									src="https://img.icons8.com/sf-regular/48/FFFFFF/stack-of-money.png"
									className={classes.boxTypeImg}
								/>
								<Typography className={classes.currencyText}>
									{place?.placeType === "hotel"
										? "Стоимость проживания: "
										: place?.placeType === "restaurant"
										? "Чек: "
										: "Цена билета: "}
								</Typography>
								<Typography className={classes.spentText}>
									{review?.spentAmount}
								</Typography>
								<Typography className={classes.currencyText}>
									{review?.currency
										? review?.currency === ""
											? currencyChange("Other")
											: currencyChange(review?.currency)
										: null}
								</Typography>
							</div>
						) : null}
						{review?.ticketType === "adult" ||
						review?.ticketType === "child" ? (
							<div className={classes.statistics}>
								<img
									src="https://img.icons8.com/sf-regular/48/FFFFFF/ticket.png"
									className={classes.boxTypeImg}
								/>
								<Typography className={classes.currencyText}>
									{review?.ticketType === "adult"
										? "Взрослый"
										: review?.ticketType === "child"
										? "Льготный"
										: null}
								</Typography>
							</div>
						) : null}
						{review?.numberOfGuests > 0 ? (
							<div className={classes.statistics}>
								<img
									src="https://img.icons8.com/sf-regular/48/FFFFFF/crowd.png"
									className={classes.boxTypeImg}
								/>
								<Typography className={classes.currencyText}>
									Кол-во гостей:{" "}
								</Typography>
								<Typography className={classes.currencyText}>
									{review?.numberOfGuests}
								</Typography>
							</div>
						) : null}
						{review?.numberOfNights > 0 ? (
							<div className={classes.statistics}>
								<img
									src="https://img.icons8.com/sf-regular/48/FFFFFF/bright-moon.png"
									className={classes.boxTypeImg}
								/>
								<Typography className={classes.currencyText}>
									Кол-во ночей:{" "}
								</Typography>
								<Typography className={classes.currencyText}>
									{review?.numberOfNights}
								</Typography>
							</div>
						) : null}
					</div>
				</CardContent>
			</Card>

			<Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
				<DialogContent style={{ position: "relative", padding: 0 }}>
					<IconButton
						className={classes.dialogNavButtons}
						style={{ left: "0" }}
						onClick={handlePrevious}
					>
						<ArrowBackIosIcon />
					</IconButton>
					<img
						src={review?.images[selectedImageIndex]}
						alt="Enlarged"
						className={classes.dialogImage}
					/>
					<IconButton
						className={classes.dialogNavButtons}
						style={{ right: "0" }}
						onClick={handleNext}
					>
						<ArrowForwardIosIcon />
					</IconButton>
					<div className={classes.positionIndicator}>
						<Typography>{selectedImageIndex + 1}</Typography>
						<Typography>/</Typography>
						<Typography>{review?.images.length}</Typography>
					</div>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default PlaceReviewCard;
