import {
	ADD_ALERT,
	DELETE_ALL_ALERTS,
	ADD_LAST_PHOTOS,
	ADD_LAST_COMMENTS,
	ADD_LAST_PLACES,
} from "../constants/actionTypes";
import * as api from "../api/index.js";

export const addClaim = (claim) => async (dispatch) => {
	try {
		const { data } = await api.addClaim(claim);
		//dispatch({ type: ADD_ALERT, payload: data });
	} catch (error) {
		console.log(error.message);
	}
};
