import React, { useEffect } from "react";
import { Pagination, PaginationItem } from "@material-ui/lab";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useStyles from "./styles.js";

const PaginationPlaceSelection = ({ page }) => {
	let numberOfPages = useSelector((state) => state.place?.numberOfPages);
	numberOfPages = numberOfPages ? numberOfPages : 1;
	const classes = useStyles();

	return (
		<Pagination
			classes={{ ul: classes.ul }}
			count={numberOfPages}
			page={Number(page) || 1}
			variant="outlined"
			color="secondary"
			renderItem={(item) => (
				<PaginationItem
					{...item}
					component={Link}
					to={`/place-selections?page=${item.page}`}
				/>
			)}
		/>
	);
};

export default PaginationPlaceSelection;
