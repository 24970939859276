import React, { useEffect, useState } from "react";
import {
	Typography,
	Link,
	Card,
	CardMedia,
	CardActionArea,
	CardContent,
	Hidden,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import useStyles from "./styles.js";
import StarRating from "../../../../../Maps/ShowGeo/PlaceBox/StarRating";
import { getPhotoByUrl } from "../../../../../../actions/photo.js";
import AuthorInfo from "../../../../../AuthorInfo/AuthorInfo.jsx";

const PlaceCard = ({ i, p, cardType, urlTypeList }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const placePhotoObject = useSelector(
		(state) => state?.photo?.selectedPhotos[`placePhoto${i}`]
	);
	console.log(i);
	console.log(placePhotoObject);

	useEffect(() => {
		cardType === "placeInSelection" &&
			p?.images &&
			p?.images.length > 0 &&
			i &&
			dispatch(getPhotoByUrl(p?.images[0]?.image, `placePhoto${i}`));
	}, [dispatch]);

	return (
		<>
			<Link
				href={
					cardType === "selection"
						? `/place-selection/${p?._id}`
						: `/place/${p?._id}`
				}
				className={classes.link}
			>
				<Card className={classes.card}>
					<CardActionArea>
						<CardContent className={classes.CardContent}>
							<div className={classes.flexHead}>
								{cardType === "placeInSelection" && (
									<div className={classes.mediaBox}>
										<CardMedia
											className={classes.media}
											image={
												p?.images && p?.images.length > 0
													? p?.images[0]?.image
													: p?.photoFromReview && p?.photoFromReview.length > 0
													? p?.photoFromReview[0]
													: p?.placeImageExample?.image
													? p?.placeImageExample?.image
													: "https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png"
											}
											alt={
												cardType === "selection"
													? "Фото подборки"
													: "Фото места"
											}
										/>
										{placePhotoObject && (
											<Hidden xsDown>
												<div className={classes.authorBox}>
													<AuthorInfo photoObject={placePhotoObject} />
												</div>
											</Hidden>
										)}
									</div>
								)}
								<div
									className={
										cardType === "placeInSelection"
											? classes.titleBoxSelection
											: classes.titleBox
									}
								>
									<Typography className={classes.title}>
										{cardType === "selection" ? p?.title : p?.placeName}
									</Typography>
									{cardType === "selection" ? null : (
										<div className={classes.rating}>
											<div className={classes.ratingFlex}>
												<StarRating
													rating={
														p?.placeRate.reduce(
															(total, rate) => total + rate.rate,
															0
														) / p?.placeRate.length || 5
													}
												/>
												<Typography className={classes.rateReports}>
													{p?.placeRate.length
														? (
																p?.placeRate.reduce(
																	(total, rate) => total + rate.rate,
																	0
																) / p?.placeRate.length
														  ).toFixed(1)
														: "N/A"}
												</Typography>
											</div>
											<Typography className={classes.numberReports}>
												Отзывов: {p?.placeRate.length}
											</Typography>
										</div>
									)}
									{cardType === "placeInSelection" ? (
										<Typography className={classes.textDescription}>
											{p?.description?.length > 500
												? `${p.description.substring(0, 500)}...`
												: p?.description}
										</Typography>
									) : (
										<Typography className={classes.textDescription}>
											{p?.description?.length > 180
												? `${p.description.substring(0, 180)}...`
												: p?.description}
										</Typography>
									)}

									{urlTypeList === "cities" ? (
										<div className={classes.cityInfoFlex}>
											{p?.population ? (
												<div className={classes.statistics}>
													<img
														src="https://img.icons8.com/sf-regular/48/FFFFFF/crowd.png"
														className={classes.boxTypeImg}
													/>
													<Typography className={classes.currencyText}>
														Население:
													</Typography>
													<Typography className={classes.currencyText}>
														{p?.population
															.toString()
															.replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
													</Typography>
												</div>
											) : null}
											{p?.numberOfConnectedPlaces > 10 ? (
												<div className={classes.statistics}>
													<Typography
														className={classes.currencyTextWithMargin}
													>
														Путеводитель готов
													</Typography>
												</div>
											) : (
												<div className={classes.statisticsBlue}>
													<Typography
														className={classes.currencyTextWithMargin}
													>
														Путеводитель в разработке
													</Typography>
												</div>
											)}
											{p?.numberOfConnectedPlaces > 0 ? (
												<div className={classes.statistics}>
													<img
														src="https://img.icons8.com/sf-regular/48/FFFFFF/address.png"
														className={classes.boxTypeImg}
													/>
													<Typography className={classes.currencyText}>
														Количество мест:
													</Typography>
													<Typography className={classes.currencyText}>
														{p?.numberOfConnectedPlaces}
													</Typography>
												</div>
											) : null}
										</div>
									) : null}
								</div>
							</div>
							{placePhotoObject && (
								<Hidden smUp>
									<div className={classes.authorBoxSmall}>
										<AuthorInfo
											type="largeMobile"
											photoObject={placePhotoObject}
										/>
									</div>
								</Hidden>
							)}
						</CardContent>
					</CardActionArea>
				</Card>
			</Link>
		</>
	);
};

export default PlaceCard;
