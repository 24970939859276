import React, { useState, useEffect } from "react";
import {
	Typography,
	TextField,
	Button,
	Paper,
	CircularProgress,
	IconButton,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import useStyles from "./styles";
import { updatePostReport } from "../../../actions/posts";
import { addYandexImage, deleteYandexImage } from "../../../actions/posts";
import Resizer from "react-image-file-resizer";
import EmojiPicker from "emoji-picker-react";
import InsertEmoticonIcon from "@material-ui/icons/InsertEmoticon";
import SubjectIcon from "@material-ui/icons/Subject";
import ImageIcon from "@material-ui/icons/Image";
import CreateIcon from "@material-ui/icons/Create";
import PublishIcon from "@material-ui/icons/Publish";
import { useHistory } from "react-router-dom";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import quillEmoji from "quill-emoji";
import "quill-emoji/dist/quill-emoji.css";
import CancelIcon from "@material-ui/icons/Cancel";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

const PostUpdate = ({ post }) => {
	const classes = useStyles();
	const history = useHistory();
	const dispatch = useDispatch();

	const user = JSON.parse(localStorage.getItem("profile"));
	const isLoading = useSelector((state) => state.posts.isLoading);
	const isLoadingPhoto = useSelector((state) => state.posts.isLoadingPhoto);

	const [textImage, setTextImage] = useState(post?.textImage);
	const [emoji, setEmoji] = useState([]);
	const [count, setCount] = useState(0);
	const [emojiValue, setEmojiValue] = useState("");
	const [textValue, setTextValue] = useState(false);
	const [postReport, setPostReport] = useState({
		...post,
	});
	const [fileName, setFileName] = useState([]);

	useEffect(() => {
		setPostReport({ ...postReport, textImage: textImage });
	}, [textImage]);

	const [photoCount, setPhotoCount] = useState(
		postReport
			? postReport?.textImage.filter((item) => item.likes === 0).length
			: 0
	);

	const [photosToDelete, setPhotosToDelete] = useState([]);

	const resizeFile = (file) =>
		new Promise((resolve) => {
			Resizer.imageFileResizer(
				file,
				1000,
				1000,
				"JPEG",
				80,
				0,
				(uri) => {
					resolve(uri);
				},
				"base64"
			);
		});

	const addText = () => {
		setCount((prevCount) => {
			return prevCount + 1;
		});
		let pushObj = { index: count, bul: false };
		setEmoji((prevEmoji) => {
			prevEmoji.push(pushObj);
			return prevEmoji;
		});
		let pushFileName = { index: count, name: "" };
		setFileName((prevArr) => {
			prevArr.push(pushFileName);
			return prevArr;
		});
		setTextImage((prevTextImage) => {
			prevTextImage.push(1);
			return prevTextImage;
		});
	};

	const addImage = () => {
		setCount((prevCount) => {
			return prevCount + 1;
		});
		setPhotoCount((prevCount) => {
			return prevCount + 1;
		});
		let pushObj = { index: count, bul: false };
		setEmoji((prevEmoji) => {
			prevEmoji.push(pushObj);
			return prevEmoji;
		});
		let pushFileName = { index: count, name: "" };
		setFileName((prevArr) => {
			prevArr.push(pushFileName);
			return prevArr;
		});
		setTextImage((prevTextImage) => {
			prevTextImage.push({
				image: "Image Upload Asked",
				description: "",
				likes: 0,
			});
			return prevTextImage;
		});
	};

	const handleClick = async () => {
		photosToDelete.length > 0
			? photosToDelete.map(async (c, i) => {
					const deletedImages = await dispatch(deleteYandexImage(c));
			  })
			: console.log("Удалять нечего");
		const dispatchPosts = await dispatch(
			updatePostReport(postReport._id, postReport)
		);
		window.location.reload(false);
	};

	const handleEmoji = (i) => {
		if (emoji.find((element) => element.index === i).index === i) {
			let arr = [...emoji];
			let prevBul = emoji.find((element) => element.index === i).bul;
			let newEmoji = { index: i, bul: !prevBul };
			arr[arr.findIndex((element) => element.index === i)] = newEmoji;
			setEmoji(arr);
		} else {
			console.log(emoji.find((element) => element.index === i).index);
		}
	};

	const handleEmojiClick = async (data, event, i) => {
		let arr = [...textImage];
		arr[i] = arr[i] + data.emoji;
		setTextImage(arr);
		setTextValue(false);
	};

	const modules = {
		toolbar: [
			[{ header: "1" }, { header: "2" }, { font: [] }],
			["bold", "italic", "underline", "strike", "blockquote"],
			[
				{ list: "ordered" },
				{ list: "bullet" },
				{ indent: "-1" },
				{ indent: "+1" },
			],
			["video"],
			["emoji"],
			["clean"],
		],
		clipboard: {
			matchVisual: false,
		},
		"emoji-toolbar": true,
		"emoji-textarea": true,
		"emoji-shortname": true,
	};

	{
		/**const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "video",
  ];**/
	}

	Quill.register(
		{
			"formats/emoji": quillEmoji.EmojiBlot,
			"modules/emoji-toolbar": quillEmoji.ToolbarEmoji,
			"modules/emoji-textarea": quillEmoji.TextAreaEmoji,
			"modules/emoji-shortname": quillEmoji.ShortNameEmoji,
		},
		true
	);
	if (isLoading) {
		return (
			<Paper className={classes.paper} elevation={0}>
				<CircularProgress color="secondary" size="7rem" />
			</Paper>
		);
	}

	return (
		<>
			{user?.result?._id === post?.creator && (
				<div style={{ width: "100%" }}>
					<div className={classes.flex}>
						<Typography gutterBottom variant="h6">
							Измените пост
						</Typography>
					</div>
					<Typography gutterBottom variant="subtitle1">
						Измените заголовок
					</Typography>
					<TextField
						fullWidth
						variant="outlined"
						label="Заголовок"
						multiline
						value={postReport.title}
						inputProps={{
							maxLength: 100,
						}}
						onChange={(e) =>
							setPostReport({ ...postReport, title: e.target.value })
						}
					/>
					<Typography>
						Измените текст или вставьте обновленное изображение
					</Typography>
					{textImage.map((c, i) => {
						const cString = c?.image + "";
						const cSplitedString = cString.split(":image/png")[0];
						const cSplitedStringYandex = cString.split(
							".storage.yandexcloud"
						)[0];
						if (
							c?.image === "Image Upload Asked" ||
							cSplitedString === "data" ||
							cSplitedStringYandex === "https://totravelru"
						) {
							return (
								<div className={classes.flexImg}>
									<div className={classes.flexImgUpload}>
										<label className={classes.labelInput}>
											<input
												key={i}
												type="file"
												className={classes.imageInput}
												filename={c}
												onChange={async (event) => {
													try {
														const file = event.target.files[0];
														const fileNameInput = event.target.files[0].name;
														textImage[i]?.image === "Image Upload Asked"
															? console.log("Файла нет")
															: textImage[i]?.image.split(
																	"totravelru.storage.yandexcloud.net/"
															  )[1] !== undefined
															? await dispatch(
																	deleteYandexImage(
																		textImage[i]?.image.split(
																			"totravelru.storage.yandexcloud.net/"
																		)[1]
																	)
															  )
															: console.log("Файла нет");
														const image = await resizeFile(file);
														let yandexImage = await dispatch(
															addYandexImage(image)
														);

														setTextImage((prevArr) => {
															let newArr = [...prevArr];
															newArr[i].image = yandexImage;
															return newArr;
														});
													} catch (err) {
														console.log(err);
													}
												}}
											/>
											{isLoadingPhoto ? null : <PublishIcon />}
											{isLoadingPhoto ? (
												<CircularProgress
													style={{ color: "white", margin: "0 auto" }}
													size="1rem"
												/>
											) : (
												<Typography className={classes.uploadText}>
													ЗАГРУЗИТЬ ФОТО
												</Typography>
											)}
										</label>
										<img
											className={classes.mediaUpdate}
											src={
												textImage[i]?.image ||
												"https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png"
											}
										/>
										<div className={classes.buttonsFlex}>
											<Button
												className={classes.buttonDelete}
												disabled={textImage.length === 1 || i === 0}
												onClick={() => {
													setTextImage((prevArr) => {
														const element = prevArr[i];
														const elementBefore = prevArr[i - 1];
														const newArr = prevArr
															.slice(0, i - 1)
															.concat(element)
															.concat(elementBefore)
															.concat(prevArr.slice(i + 1));
														return newArr;
													});
												}}
											>
												<ArrowDropUpIcon />
											</Button>
											<Button
												className={classes.buttonDelete}
												onClick={() => {
													setTextImage((prevArr) => {
														const newArrDelete = prevArr
															.slice(0, i)
															.concat(prevArr.slice(i + 1));
														return newArrDelete;
													});
													setPhotoCount((prevPhotoCount) => {
														return prevPhotoCount - 1;
													});
													setPhotosToDelete((prevArr) => {
														const textToPush = textImage[i]?.image.split(
															"totravelru.storage.yandexcloud.net/"
														)[1];
														prevArr.push(textToPush);
														const newArr = prevArr;
														return newArr;
													});
												}}
											>
												<CancelIcon />
											</Button>
											<Button
												className={classes.buttonDelete}
												disabled={
													textImage.length === 1 || i + 1 === textImage.length
												}
												onClick={() => {
													setTextImage((prevArr) => {
														const element = prevArr[i];
														const elementAfter = prevArr[i + 1];
														const newArr = prevArr
															.slice(0, i)
															.concat(elementAfter)
															.concat(element)
															.concat(prevArr.slice(i + 2));
														return newArr;
													});
												}}
											>
												<ArrowDropDownIcon />
											</Button>
										</div>
									</div>

									{/*<TextField
                    key={i}
                    className={classes.textInputPost}
                    variant="outlined"
                    label="Описание"
                    rows={1}
                    value={c.description.value}
                    onChange={(e) =>
                      setTextImage((prevArr) => {
                        prevArr[i].description = e.target.value;
                        return prevArr;
                      })
                    }
                  />*/}
								</div>
							);
						} else {
							return (
								<div className={classes.quillBox}>
									<div className={classes.quillWrap}>
										<ReactQuill
											key={i}
											modules={modules}
											//formats={formats}
											theme="snow"
											value={c}
											onChange={(e) =>
												setTextImage((prevArr) => {
													let newArr = [...prevArr];
													setTextValue(true);
													newArr[i] = e;
													return newArr;
												})
											}
										/>
									</div>
									<div className={classes.buttonsFlex}>
										<Button
											className={classes.buttonDelete}
											disabled={textImage.length === 1 || i === 0}
											onClick={() => {
												setTextImage((prevArr) => {
													const element = prevArr[i];
													const elementBefore = prevArr[i - 1];
													const newArr = prevArr
														.slice(0, i - 1)
														.concat(element)
														.concat(elementBefore)
														.concat(prevArr.slice(i + 1));
													return newArr;
												});
											}}
										>
											<ArrowDropUpIcon />
										</Button>
										<Button
											className={classes.buttonDelete}
											onClick={() => {
												setTextImage((prevArr) => {
													const newArrDelete = prevArr
														.slice(0, i)
														.concat(prevArr.slice(i + 1));
													return newArrDelete;
												});
											}}
										>
											<CancelIcon />
										</Button>
										<Button
											className={classes.buttonDelete}
											disabled={
												textImage.length === 1 || i + 1 === textImage.length
											}
											onClick={() => {
												setTextImage((prevArr) => {
													const element = prevArr[i];
													const elementAfter = prevArr[i + 1];
													const newArr = prevArr
														.slice(0, i)
														.concat(elementAfter)
														.concat(element)
														.concat(prevArr.slice(i + 2));
													return newArr;
												});
											}}
										>
											<ArrowDropDownIcon />
										</Button>
									</div>
									{/**<TextField
                    key={i}
                    className={classes.textInputPost}
                    fullWidth
                    variant="outlined"
                    label="Текст"
                    multiline
                    onFocus={() => setTextValue(true)}
                    value={textValue === true ? c.value : c}
                    onChange={(e) =>
                      setTextImage((prevArr) => {
                        setTextValue(true);
                        prevArr[i] = e.target.value;
                        return prevArr;
                      })
                    }
                  />
                  <Button
                    onClick={() => handleEmoji(i)}
                    className={
                      emoji
                        ? emoji[i]?.bul
                          ? classes.buttonEmojiClicked
                          : classes.buttonEmoji
                        : null
                    }
                  >
                    <InsertEmoticonIcon className={classes.imageEmoji} />
                  </Button>
                  {emoji ? (
                    emoji[i]?.bul ? (
                      <EmojiPicker
                        onEmojiClick={(data, event) =>
                          handleEmojiClick(data, event, i)
                        }
                      />
                    ) : null
                  ) : null}
                  <Typography>{emojiValue}</Typography>**/}
								</div>
							);
						}
					})}
					<div className={classes.buttonsPost}>
						<Button
							style={{ marginTop: "10px" }}
							className={classes.buttonPrimary}
							disabled={!postReport.title || isLoadingPhoto}
							variant="contained"
							color="primary"
							onClick={addText}
						>
							<SubjectIcon className={classes.iconButton} />
							<Typography className={classes.textButton}>
								Добавить текст
							</Typography>
						</Button>
						<Button
							style={{ marginTop: "10px" }}
							className={classes.buttonPrimary}
							disabled={
								!postReport.title ||
								isLoadingPhoto ||
								user?.result.userType === undefined
									? photoCount >= 10
									: photoCount >= 20
							}
							variant="contained"
							color="primary"
							onClick={addImage}
						>
							<ImageIcon className={classes.iconButton} />
							<Typography className={classes.textButton}>
								Добавить изображение (не более{" "}
								{user?.result.userType === undefined ? "10" : "20"})
							</Typography>
						</Button>
					</div>
					<div className={classes.buttonsPost}>
						<Button
							style={{ marginTop: "10px" }}
							className={classes.buttonPrimary}
							disabled={
								!postReport.title ||
								postReport?.textImage.find(
									(item) => item.image === "Image Upload Asked"
								) ||
								postReport?.textImage.find((item) => item === 1) ||
								isLoadingPhoto
							}
							variant="contained"
							color="primary"
							onClick={handleClick}
						>
							<CreateIcon className={classes.iconButton} />
							<Typography className={classes.textButton}>
								Сохранить изменения
							</Typography>
						</Button>
					</div>
				</div>
			)}
		</>
	);
};

export default PostUpdate;
