import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
	Grid,
	Grow,
	Typography,
	Paper,
	Button,
	Menu,
	MenuItem,
} from "@material-ui/core";
import useStyles from "./styles";
import { useSelector, useDispatch } from "react-redux";
import { batch } from "react-redux";
import { useLocation } from "react-router-dom";
import { translatePlaceTypePlural } from "../../../../utils/placeUtils.js";
import PlaceCard from "../PlaceCityToDo/PlacesInCityList/PlaceCard/PlaceCard.jsx";
import PaginationPlaces from "./PaginationPlaces/PaginationPlaces.jsx";
import { findPlaces, findPlacesByCity } from "../../../../actions/places.js";
import { getPlace } from "../../../../actions/place.js";
import PlacesListMenu from "./PlacesListMenu/PlacesListMenu.jsx";

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const PlacesList = () => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const query = useQuery();
	const page = query.get("page") || 1;
	const urlPlaceId = window.location.pathname.split("/")[2];
	const urlType = window.location.pathname.split("/")[3];
	const urlTypeList = window.location.pathname.split("/")[1];

	const city = useSelector((state) => state?.place?.place);
	const placesInCity = useSelector((state) => state.place.placesByCity);
	const isLoading = useSelector((state) => state.place?.isLoading);

	const [isShowPlacesListMenu, setIsShowPlacesListMenu] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null); // For the sorting menu
	const [sortType, setSortType] = useState("default");

	const handleSort = (type) => {
		setSortType(type);
		setAnchorEl(null); // Close the menu
		dispatch(findPlaces(page, "city", type)); // Dispatch sorting action
	};

	{
		/*useEffect(() => {
		dispatch(findPlacesByCity(urlPlaceId, page, urlType));
		dispatch(getPlace(urlPlaceId));
	}, []);*/
	}

	useEffect(() => {
		async function fetchData() {
			if (urlTypeList === "cities") {
				await dispatch(findPlaces(page, "city", sortType));
			} else {
				await batch(async () => {
					dispatch(findPlacesByCity(urlPlaceId, page, urlType));
					await dispatch(getPlace(urlPlaceId));
				});
			}
		}
		fetchData();
	}, [page, sortType]);

	return (
		<div>
			<Helmet>
				<title>
					{urlTypeList === "cities"
						? "Список городов - ToTravelRu"
						: city
						? urlType === "hotel"
							? `${translatePlaceTypePlural(urlType)} города ${
									city?.placeName
							  }, где остановиться в городе ${city?.placeName}  - ToTravelRu`
							: urlType === "restaurant"
							? `${translatePlaceTypePlural(urlType)} города ${
									city?.placeName
							  }, где поесть в городе ${city?.placeName}  - ToTravelRu`
							: urlType === "museum" || urlType === "site"
							? `${translatePlaceTypePlural(urlType)} города ${
									city?.placeName
							  }, что посетить в городе ${city?.placeName}  - ToTravelRu`
							: `Места города ${city?.placeName} - ToTravelRu`
						: `ToTravelRu`}
				</title>
				<meta
					name="description"
					content={
						urlTypeList === "cities"
							? "Список городов - ToTravelRu"
							: city
							? urlType === "hotel"
								? `${translatePlaceTypePlural(urlType)} города ${
										city?.placeName
								  }, где остановиться в городе ${city?.placeName}  - ToTravelRu`
								: `ToTravelRu`
							: `ToTravelRu`
					}
				/>
				<meta
					name="keywords"
					content={
						urlTypeList === "cities"
							? "список городов"
							: `${translatePlaceTypePlural(
									urlType
							  )}, город, что посетить, путешествия, travel, totravel, ToTravelRu`
					}
				/>
			</Helmet>

			<div className={classes.titlePlaces}>
				<div className={classes.titlePlacesBox}>
					{urlTypeList === "cities" ? (
						<>
							<Typography variant="h5" className={classes.titlePlacesText}>
								Список городов
							</Typography>
							<Button
								aria-controls="sort-menu"
								aria-haspopup="true"
								onClick={(e) => setAnchorEl(e.currentTarget)}
								variant="contained"
								className={classes.buttonPrimary}
							>
								Сортировать
							</Button>
							<Menu
								id="sort-menu"
								anchorEl={anchorEl}
								keepMounted
								open={Boolean(anchorEl)}
								onClose={() => setAnchorEl(null)}
							>
								<MenuItem onClick={() => handleSort("byPopulation")}>
									Численность населения
								</MenuItem>
								{/*  <MenuItem onClick={() => handleSort("byPlaces")}>
									Количество мест
								</MenuItem>*/}
								<MenuItem onClick={() => handleSort("byReviews")}>
									Количество отзывов
								</MenuItem>
							</Menu>
						</>
					) : (
						<Typography variant="h5" className={classes.titlePlacesText}>
							{translatePlaceTypePlural(urlType)} города {city?.placeName}
						</Typography>
					)}

					{urlTypeList === "cities" ? null : (
						<div>
							<Button
								onClick={() => {
									setIsShowPlacesListMenu((prev) => !prev);
								}}
								variant="contained"
								className={classes.buttonPrimary}
							>
								{isShowPlacesListMenu
									? "Закрыть"
									: `Больше мест города ${city?.placeName}`}
							</Button>
						</div>
					)}
				</div>

				{isShowPlacesListMenu ? <PlacesListMenu city={city} /> : null}
			</div>

			{isLoading ? (
				<Grid
					container
					justifyContent="space-between"
					alignItems="stretch"
					spacing={2}
					className={classes.gridContainer}
				>
					<Grid item xs={12} sm={12} md={6}>
						<PlaceCard cardType="large" />
					</Grid>
					<Grid item xs={12} sm={12} md={6}>
						<PlaceCard cardType="large" />
					</Grid>
				</Grid>
			) : (
				<Grow in>
					<Grid
						container
						justifyContent="space-between"
						alignItems="stretch"
						spacing={2}
						className={classes.gridContainer}
					>
						{placesInCity
							? placesInCity.map((p, i) => (
									<Grid key={i} item xs={12} sm={12} md={6}>
										<PlaceCard
											urlTypeList={urlTypeList}
											p={p}
											cardType="large"
										/>
									</Grid>
							  ))
							: null}
					</Grid>
				</Grow>
			)}

			{placesInCity?.length ? (
				<Paper className={classes.pagination}>
					<PaginationPlaces
						page={page}
						urlType={urlType}
						urlPlaceId={urlPlaceId}
						urlTypeList={urlTypeList}
					/>
				</Paper>
			) : null}
		</div>
	);
};

export default PlacesList;
